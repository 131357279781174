import { RouteFactory } from "@app/routePaths";
import { Budget2024Data } from "@app/types/Page";
import budgetLogo from "@assets/logo-budget2024-v3.svg";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import SGBudget2024Banner from "@components/Banner/SGBudget2024Banner";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { TagType } from "@pages/Section/types";
import Highlights from "@pages/SingaporeBudget/2024/components/Highlights";
import LatestNews from "@pages/SingaporeBudget/2024/components/LatestNews";
import RelatedStoriesOverview from "@pages/SingaporeBudget/2024/components/RelatedStoriesOverview";
import Telegram from "@pages/SingaporeBudget/2024/components/Telegram";
import Videos from "@pages/SingaporeBudget/2024/components/Videos";
import Views from "@pages/SingaporeBudget/2024/components/Views";
import { useDetectAdBlock } from "adblock-detect-react";

import Booklet from "./components/Booklet";
import Infographics from "./components/Infographics";

type SingaporeBudget2024Props = {
  title: string;
  data: Budget2024Data;
};

export default function SingaporeBudget2024({
  title,
  data: { highlights, latestNews, views, relatedStories },
}: SingaporeBudget2024Props): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full" data-testid="singapore-budget-2024-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.budget2024}
        adTargets={[
          { key: "page", value: "listing" },
          {
            key: "bttags",
            value: getFormattedTextForAds("Singapore Budget 2024"),
          },
        ]}
      />

      <MetaTags
        title={title}
        description={
          "Deputy Prime Minister Lawrence Wong will present Singapore's Budget 2024 in Parliament on Feb 16. Get the latest updates on BT's dedicated microsite."
        }
        ogType="website"
        slug="/singapore-budget-2024"
        keywords={[
          "singapore budget 2023 summary",
          "how much singapore reserves",
          "who approved singapore budget",
          "singapore budget news",
          "singapore budget quiz",
          "singapore budget 2024 date",
          "singapore budget 2024 payout",
          "singapore budget 2024 announcement",
          "budget 2024 payout date",
          "singapore budget on education",
          "how much singapore spent on covid",
          "singapore budget over the years",
        ]}
      />

      <GAData
        title="Singapore_Budget_2024"
        level2="miscellaneous"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <div className="mb-6 flex items-center justify-between">
          <KeywordFilter
            tag={{
              name: "Singapore Budget 2024",
              uri: "/keywords/singapore-budget-2024",
              aliases: [],
              fields: [],
              id: "singapore-budget-2024",
              parent: null,
              sections: [],
              type: TagType.Keyword,
              urlPath: "/keywords/singapore-budget-2024",
              urlPathHistory: [],
            }}
            rootClassName="bg-gray-250 p-3 inline-block"
          />

          <img
            src={budgetLogo}
            width={43}
            height={60}
            alt="Singapore Budget 2024"
          />
        </div>

        <Row rootClassName="mb-10">
          <Column rootClassName="w-full lg:w-8/12">
            <Highlights
              articles={highlights}
              rootClassName="border-top border-gray-850 mb-6 lg:mb-0"
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Booklet rootClassName="border-top border-gray-850 pt-2 mb-10" />

            <LatestNews
              articles={latestNews}
              rootClassName="border-top border-gray-850 pt-2 mb-10"
            />

            <Infographics rootClassName="border-top border-gray-850 pt-2" />
          </Column>
        </Row>

        <Row rootClassName="mb-10">
          <Column rootClassName="w-full mb-10">
            <SGBudget2024Banner
              copyText="Unlock all of BT's coverage on Budget 2024."
              link="https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=btbudget24&utm_medium=sph-publication&utm_source=banner&utm_content=btp99-banner-microsite"
              buttonText="SUBSCRIBE NOW"
              buttonClass="bg-orange-300 border-orange-300 !px-4"
              imgClass="hidden xs:block"
              headerClass="xs:ml-2 xs:border-l xs:border-gray-850 xs:border-opacity-20 xs:pl-2"
              rootClassName="sm:px-4"
            />
          </Column>

          <Column rootClassName="w-full lg:w-8/12">
            <Views
              articles={views}
              rootClassName="border-top border-gray-850 pt-2"
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 lg:px-5">
            <Telegram />
          </Column>
        </Row>

        <Videos rootClassName="border-top border-gray-850 pt-2 mb-10" />

        <Row rootClassName="mb-10">
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <RelatedStoriesOverview
              articles={relatedStories}
              className="border-top border-gray-850 pt-2"
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Ad
              adType="imu2"
              className="flex items-center justify-center lg:sticky lg:top-[70px]"
            />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
