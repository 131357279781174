import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { MyAccountPageSettingProps } from "@pages/MyAccount/utils/types";
import { mySPHOpenUpdatePassword } from "@util/helpers";

export default function MyAccountPageSetting({
  oktaUserInfo,
}: MyAccountPageSettingProps): React.ReactElement {
  const [manageAccountLink, setManageAccountLink] = useState<string>("");
  const handleUpdatePassword = () => {
    mySPHOpenUpdatePassword();
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const url = ` https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
      location.href
    )}`;
    setManageAccountLink(url);
  }, []);

  return (
    <div data-testid="my-account-setting-component">
      <div className="cursor-pointer py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 underline-offset-2 hover:underline">
        <a target="_blank" href={manageAccountLink} rel="noreferrer">
          Manage Account
        </a>
      </div>

      <button
        className="cursor-pointer py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 underline-offset-2 hover:underline"
        onClick={handleUpdatePassword}
      >
        Update your password
      </button>

      <Link
        to={RouteFactory.help}
        reloadDocument
        className="block cursor-pointer py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 underline-offset-2 hover:underline"
      >
        Help
      </Link>

      <a
        className="cursor-pointer py-2 font-poppins text-2xs font-semibold leading-loose tracking-2% text-gray-850 underline-offset-2 hover:underline"
        href="https://subscribe.sph.com.sg/contact-us-mobile/"
      >
        Contact Us
      </a>

      <span id="sph_user_name" className="hidden">
        {encodeURI(oktaUserInfo?.loginid || "")}
      </span>
    </div>
  );
}
