import { useEffect, useState } from "react";

export const useDetectWhiteOverlay = () => {
  // Handles state for white overlay
  const [isOverlayDisplay, setIsOverlayDisplay] = useState(true);

  const setOverlayDisplayed = (mutationList: MutationRecord[]) => {
    for (const mutation of mutationList) {
      if (mutation.type !== "attributes") return;
      const target = mutation.target as HTMLElement;
      if (target.classList.contains("overlayWhite")) return;

      setIsOverlayDisplay(false);
    }
  };

  useEffect(() => {
    // Add mutation observer to detect changes if there a change in the overlay element
    if (typeof window === "undefined") return;

    const appElement = window.document.querySelector("#MyPageOverlay");

    if (!appElement) return;
    const observer = new MutationObserver(setOverlayDisplayed);
    observer.observe(appElement, {
      attributes: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  return { isOverlayDisplay };
};
