/**
 * Company: SPHMedia
 * Description: SME Latest Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import VerticalBlockTitle from "@components/VerticalBlockTitle/VerticalBlockTitle";
import { useHandleMoreStories } from "@hooks/useHandleMoreStories";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import type { SectionDefaultProps } from "@pages/Section/types";

import SMEBanner from "./components/SMEBanner";
import SMEListingStories from "./components/SMEListingStories";
import SMENewsletterDescription from "./components/SMENewsletterDescription";

const PAGE_SIZE = 10;

export default function SMEVerticalLatest({
  data: { title, overview, urlPath },
}: SectionDefaultProps): ReactElement {
  const uniqueCategoryQuery = urlPath
    .replace(/^\/+/, "")
    .replaceAll("/", "_")
    .replace("_latest", "");

  const { ref, loadMoreData } = useHandleMoreStories({
    data: overview,
    uniqueCategoryQuery,
    parentCategory: "",
    pageSize: PAGE_SIZE,
  });

  return (
    <div className="w-full">
      <AdSettings
        adNames={["lb1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.globalEnterprise}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={`${RouteFactory.sgsme}/latest`}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="py-4">
        <SMEBanner />

        <VerticalBlockTitle rootClassName="mb-4" text="Latest" />
        <ul
          data-testid="sme-vertical-latest-list"
          className="flex flex-col divide-y"
        >
          {loadMoreData.map((article, index) => {
            const uniqueName = article.sections?.[0]?.uniqueName;

            if (!uniqueName) return null;

            return (
              <li className="py-4" key={article.id}>
                <SMEListingStories
                  uniqueName={uniqueName}
                  article={article}
                  index={index}
                />
              </li>
            );
          })}
        </ul>

        <div ref={ref} />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<SMENewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.SGSME
        )}
        btnColors="bg-verticals-sme text-white hover:bg-white hover:text-verticals-sme"
        linkColors="text-verticals-sme"
      />

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
