import { useEffect, useState } from "react";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Transition, TransitionChild } from "@headlessui/react";
import { useHideComponents } from "@hooks/useHideComponents";
import { cn } from "@util/helpers";

export type MyAccountToolTipProps = {
  text: string;
  isLogin?: string;
};

export default function MyAccountLoginTooltip(): React.ReactElement {
  const [isVisited, setIsVisited] = useState(true);
  const { isHide, handleSetIsHide, isTimeout } = useHideComponents(5000);
  const sendDataToGTM = useGTMDispatch();

  const handleLoginTooltip = () => {
    handleSetIsHide(true);
  };

  useEffect(() => {
    const session = localStorage.getItem("my-account-login-tooltip");
    if (session) return;

    setIsVisited(false);
  }, []);

  useEffect(() => {
    if (!isTimeout) return;

    localStorage.setItem("my-account-login-tooltip", "shown");
  }, [isTimeout]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (isVisited) return;
    if (isHide) return;

    sendDataToGTM({
      event: "custom_event",
      eventCategory: "mybt",
      eventAction: "tooltip visible",
      eventLabel: window.location.href,
    });
  }, [isVisited, isHide, sendDataToGTM]);

  return (
    <div data-testid="my-account-tooltip-component">
      <Transition show={!isVisited && !isHide}>
        <TransitionChild
          enter="transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative top-3 mr-2 transition-all duration-300 lg:mr-0">
            <div
              className={cn(
                "absolute -top-2 right-[6px] h-0 w-0 border-b-[15px] border-l-[10px] border-r-[10px] border-b-gray-850 border-l-transparent border-r-transparent"
              )}
            ></div>

            <div className="flex w-[280px] items-center justify-center rounded-md bg-gray-850 p-3 text-white lg:w-[295px]">
              <p className="mb-0 ml-2 font-poppins text-xs font-medium tracking-2%">
                Update: You can now access myBT under your profile!
              </p>

              <Icon
                className="flex-none cursor-pointer"
                type={IconTypeEnum.close}
                fill="white"
                onClick={handleLoginTooltip}
              />
            </div>
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}
