import { routesPodcastsKeywords } from "@app/routePaths";
import { Tag, TagField } from "@app/types/Cue";
import {
  defaultKeywordDataFetcher,
  fetchWithHighlightKeywordDataFetcher,
} from "@pages/Keyword/Keyword.server";

import { FieldKey } from "./types";

/**
 * Helper function to get the page size for the keyword page
 * @param path The path of the keyword page
 * @returns {number} The page size
 */
export const getKeywordPageSize = (path: string): number => {
  if (routesPodcastsKeywords.includes(path)) return 5;

  return 10;
};

/**
 * Helper function to determine which data fetcher to use based on the tag path
 * @param tag The tag object containing path information
 * @returns The appropriate data fetcher function for the given path
 */
export const whichKeywordDataFetcher = (tag: Tag) => {
  const template = getFieldValueByKey(tag.fields, FieldKey.Template);

  if (typeof template === "string") {
    return getKeywordDataFetcherByTemplate(template);
  }

  return defaultKeywordDataFetcher;
};

/**
 * Returns the appropriate keyword data fetcher function based on the template type
 * @param {string} template - Template identifier (e.g. "withHighlightArticles")
 * @returns defaultKeywordDataFetcher for unknown templates, fetchWithHighlightKeywordDataFetcher for "withHighlightArticles"
 */
export const getKeywordDataFetcherByTemplate = (template: string) => {
  switch (template) {
    case "withHighlightArticles":
      return fetchWithHighlightKeywordDataFetcher;

    default:
      return defaultKeywordDataFetcher;
  }
};

/**
 * Extracts and returns an array of story queue IDs from a tag's fields
 * @param {Tag} tag - The tag object containing story queue information in its fields
 * @returns {string[]} An array of story queue IDs, or empty array if no valid story queues found
 */
export const getStoryQueueIdsFromKeyword = (tag: Tag): string[] => {
  const storyQueues = getFieldValueByKey(tag.fields, FieldKey.StoryQueues);

  if (typeof storyQueues === "undefined") return [];

  return storyQueues.split(",");
};

/**
 * Helper function to get the value from a key-value pair array by key.
 * @param fields - Array of key-value pairs to search through
 * @param key - The key to look up
 * @returns {string|undefined} The value associated with the key if found, undefined otherwise
 */
export const getFieldValueByKey = (
  fields: TagField[],
  key: string
): string | undefined => {
  const field = fields.find((field) => field.key === key);
  const caasValue = field?.["value-caas"];

  if (caasValue) return caasValue;

  return field?.value;
};
