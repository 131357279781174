import { useDetectWhiteOverlay } from "@hooks/useDetectWhiteOverlay";
import MyAccountLoginTooltip from "@pages/MyAccount/components/MyAccountHeader/MyAccountLoginTooltip";
import MyAccountUserHeader from "@pages/MyAccount/components/MyAccountHeader/MyAccountUserHeader";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { cn } from "@util/helpers";

export default function MyAccountHeader(): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const displaySubscribe =
    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER;

  const { isOverlayDisplay } = useDetectWhiteOverlay();

  return (
    <div
      className="relative flex-none"
      data-testid="my-account-header-component"
    >
      <div className={cn("relative z-10", { "mr-4": displaySubscribe })}>
        <MyAccountUserHeader />
      </div>

      <div className="absolute right-0 z-[10000]">
        {!isOverlayDisplay ? <MyAccountLoginTooltip /> : null}
      </div>
    </div>
  );
}
