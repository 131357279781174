import { useEffect, useState } from "react";
import Icon from "@components/Icon/Icon";
import { IconTypeEnum } from "@components/Icon/types";
import { Transition, TransitionChild } from "@headlessui/react";
import { useDelayedComponents } from "@hooks/useDelayedComponents";
import { useHideComponents } from "@hooks/useHideComponents";
import { cn } from "@util/helpers";
import Cookies from "js-cookie";

export type MyAccountToolTipProps = {
  text: string;
  isLogin?: string;
};

export default function MyAccountAnonTooltip(): React.ReactElement {
  const [isVisited, setIsVisited] = useState(true);
  const { isHide, handleSetIsHide, isTimeout } = useHideComponents(10000);
  const { isShown } = useDelayedComponents(2500);

  const handleLoginTooltip = () => {
    handleSetIsHide(true);
  };

  useEffect(() => {
    const cookie = Cookies.get("my-account-anon-tooltip");
    if (typeof cookie !== "undefined") return;

    setIsVisited(false);
  }, []);

  useEffect(() => {
    if (!isTimeout) return;

    Cookies.set("my-account-anon-tooltip", "shown");
  }, [isTimeout]);

  return (
    <div data-testid="my-account-tooltip-component">
      <Transition show={!isVisited && !isHide && isShown}>
        <TransitionChild
          enter="transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative top-2 transition-all duration-300">
            <div
              className={cn(
                "absolute -top-2 right-[14px] h-0 w-0 border-b-[15px] border-l-[10px] border-r-[10px] border-b-gray-850 border-l-transparent border-r-transparent lg:right-12"
              )}
            ></div>

            <div className="flex w-[280px] items-center justify-center rounded-md bg-gray-850 p-3 text-white lg:w-[295px]">
              <p className="mb-0 ml-2 font-poppins text-xs font-medium tracking-2%">
                Find your recently read articles and latest followed keyword
                updates here.
              </p>

              <Icon
                className="flex-none cursor-pointer"
                type={IconTypeEnum.close}
                fill="white"
                onClick={handleLoginTooltip}
              />
            </div>
          </div>
        </TransitionChild>
      </Transition>
    </div>
  );
}
