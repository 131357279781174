import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { Tag } from "@app/types/Cue";
import { MyBTOnboardContext } from "@app/types/Page";
import Button from "@components/AccessibleComponents/Button";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import KeywordFilter from "@components/KeywordFilter";
import MetaTags from "@components/MetaTags/MetaTags";
import MyBTCarousel from "@components/MyBT/MyBTCarousel";
import { faChevronRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@hooks/useWindowSize";
import OnboardSearchBar from "@pages/MyBT/components/Onboard/OnboardSearchBar";
import {
  myBTRecommendedKeywords,
  myBTRegionsKeywords,
  myBTStocksKeywords,
} from "@pages/MyBT/constants";
import { getRedirectionLink } from "@pages/MyBT/utils/helpers";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { MYBT_REG_LIMIT, MYBT_SUB_LIMIT } from "@util/constant";
import { cn, mergeArrays } from "@util/helpers";

type MyBTOnboardPageContentProps = Pick<MyBTOnboardContext, "data">;

export default function MyBTOnboardPageContent({
  data: { title, myBTRegionsData, myBTStocksData },
}: MyBTOnboardPageContentProps): React.ReactElement {
  const { isScreenMD } = useWindowSize();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [displayItems, setDisplayItems] = useState<Tag[]>([]);
  const [isFromAccountPage, setIsFromAccountPage] = useState(false);
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  useEffect(() => {
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.ANONYMOUS ||
    OKTAUserInfo?.mysphw == ""
      ? navigate("/mybt/intro")
      : null;
  }, [OKTAUserInfo, navigate]);

  const [followedTags, setIsLimitModalShown] = useMyBtStore((store) => [
    store.tags,
    store.setIsLimitModalShown,
  ]);
  const disableButton = followedTags.length == 0;
  const userTagLimit =
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER
      ? MYBT_SUB_LIMIT
      : MYBT_REG_LIMIT;
  const progress_width = 100 * (followedTags.length / userTagLimit);

  const myBTUserTagsData = useMemo(() => {
    return mergeArrays(followedTags, myBTRecommendedKeywords);
  }, [followedTags]);

  useEffect(() => {
    if (expanded) setDisplayItems(myBTUserTagsData);
    else
      setDisplayItems(
        isScreenMD ? myBTUserTagsData : myBTUserTagsData.slice(0, 7)
      );
    setIsLoading(false);
  }, [myBTUserTagsData, followedTags, isScreenMD, expanded]);

  const showMore = () => {
    setDisplayItems(myBTUserTagsData);
    setExpanded(true);
  };

  const onClose = () => {
    setIsLimitModalShown(false);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const isFromAccountPage =
      sessionStorage.getItem("from-account-page") === "true";

    setIsFromAccountPage(isFromAccountPage);
  }, []);

  return (
    <>
      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.myBTOnboard}
      />

      <GAData
        chapter1="mybt onboard"
        level2="mybt"
        title="myBT Onboard - THE BUSINESS TIMES"
        adblocker={typeof window !== undefined ? 0 : window.canRunAds}
      />

      {isLoading ? (
        <FontAwesomeIcon
          icon={faSpinner}
          size="3x"
          spin
          className="mb-6"
          height="3em"
        />
      ) : (
        <div className="fixed h-full w-full overflow-y-auto bg-white">
          <div className="relative">
            <div className="fixed left-0 top-0 z-10 w-full bg-white">
              <div className="border-neutral-100 flex items-center justify-between rounded-t-md border-b-2 border-opacity-100 p-4">
                <div className="text-neutral-800 dark:text-neutral-200 mx-auto w-full text-xl font-medium md:w-2/3 lg:w-1/3">
                  <OnboardSearchBar />
                </div>

                <Link
                  reloadDocument
                  onClick={onClose}
                  to={getRedirectionLink(isFromAccountPage, disableButton)}
                  className="fixed right-0 top-4 z-10 mr-4 mt-1 box-content  rounded-none border-none !outline-0 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </Link>
              </div>
            </div>

            <div className="relative overflow-hidden pt-16">
              <div className="mb-3 mt-10 w-full px-3">
                <h2 className="container w-full text-base font-bold uppercase text-[#2b2b2b] lg:text-center lg:text-3xl">
                  Select your keywords
                </h2>
              </div>

              <Column rootClassName="w-full  py-8 bg-gray-250">
                <div className="container flex w-full flex-wrap gap-2 md:justify-center lg:max-w-[50%]">
                  {displayItems.map((tag: Tag, index) => {
                    return (
                      <KeywordFilter
                        key={index}
                        tag={tag}
                        tagClassName="!text-xs !font-normal"
                        rootClassName="border rounded-full px-2 py-1"
                        iconClassName="bg-black text-white rounded-full w-[1em] h-[1em] p-1"
                        disableTooltip
                        disableBorder
                        isLinkenabled={false}
                        testId="mybt-keyword-button"
                      />
                    );
                  })}
                  {!expanded && myBTUserTagsData.length > 5 && (
                    <Button
                      onPress={showMore}
                      className="show-more-button block font-bold uppercase md:hidden"
                    >
                      See More
                    </Button>
                  )}
                </div>
              </Column>

              <Column rootClassName="w-full mb-10">
                <MyBTCarousel
                  results={myBTRegionsData}
                  keywords={myBTRegionsKeywords}
                  page="onboard"
                  showTooltip={false}
                  rootClassName="pt-3 container pb-10"
                  title="regions"
                />

                <MyBTCarousel
                  results={myBTStocksData}
                  keywords={myBTStocksKeywords}
                  page="onboard"
                  showTooltip={false}
                  rootClassName="pt-3 container pb-20"
                  title="stocks"
                />
              </Column>
            </div>

            <div className="border-neutral-100 fixed bottom-0 left-0 right-0 border-t-2 border-opacity-100 bg-white p-4">
              <div className="mx-auto w-full lg:w-3/10">
                <div className="mb-1 flex items-center justify-between">
                  {followedTags.length} of {userTagLimit} keywords
                  {OKTAUserInfo?.usertype === OKTAUserTypeEnum.REGISTERED && (
                    <Link
                      target="_blank"
                      to={
                        "https://subscribe.sph.com.sg/publications-bt/?utm_campaign=bt_subscription&utm_medium=sph-publication&utm_source=bt&utm_content=mybt"
                      }
                      className="text-xs font-bold"
                    >
                      SUBSCRIBE FOR MORE
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        size="sm"
                        className="pl-1"
                      />
                    </Link>
                  )}
                </div>

                <div className="mb-4 h-1 bg-gray-200">
                  <div
                    className="h-1 bg-gray-850"
                    style={{ width: `${progress_width}%` }}
                  ></div>
                </div>

                <Link
                  reloadDocument
                  onClick={onClose}
                  to={disableButton ? "/mybt/intro" : "/mybt"}
                  className={cn(
                    "text-md block w-full rounded-full bg-gray-850 px-6 py-2 text-center font-bold uppercase text-white !outline-0 transition duration-150 ease-in-out",
                    {
                      "pointer-events-none hover:bg-gray-675 hover:opacity-60":
                        disableButton,
                    }
                  )}
                >
                  SAVE AND SEE MY PAGE
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
