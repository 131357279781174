import emptyStateMyBT from "@assets/my-account/my-account-empty-myBt.jpg";
import emptyStateRead from "@assets/my-account/my-account-empty-read.jpg";
import { MyAccountTabsEnum } from "@pages/MyAccount/utils/types";

export const MyAccountEmptyStateMyBTConstants = {
  key: MyAccountTabsEnum.myBT,
  src: emptyStateMyBT,
  title: "Your personalised content",
  description: "Sign in for free and start following your topics of interest.",
  afterLoginDescription: "You have not followed any keywords yet.",
};

export const MyAccountEmptyStateReadConstants = {
  key: MyAccountTabsEnum.recent,
  src: emptyStateRead,
  title: "Your reading history",
  description: "Sign in for free and access all your Recent read stories.",
  afterLoginDescription: "You have not read any articles yet.",
};

export const MyAccountRecentReadPageEmptyState = {
  key: MyAccountTabsEnum.recent,
  src: emptyStateRead,
  description: "You have not read any articles yet.",
  descriptionLine2: "Recent articles you have read on BT will appear here.",
};
