import * as React from "react";
import { defer, RouteObject } from "react-router-dom";
import type { Element, SearchResultItemData, Tag } from "@app/types/Cue";
import { CaasEvent, CueEventState } from "@app/types/enums";
import type { NavigationItem } from "@app/types/Navigation";
import type {
  CaasConcisePayload,
  OpensearchSearchLambdaAxiosResponseType,
  OpensearchSearchLambdaRequestConfig,
  OriginResponsePayload,
  PartialCueArticle,
} from "@app/types/Opensearch";
import { getEnvStoryQueueId } from "@caas/getEnvStoryQueueId";
import { genArticleUrlPathQuery } from "@caas/queries/genArticleUrlPathQuery";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import CustomError from "@components/Error/CustomError";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import Spinner from "@components/Spinner/Spinner";
import AuthorPage from "@pages/Author/AuthorPage";
import BreakingNewsPage from "@pages/BreakingNews/BreakingNewsPage";
import AboutUs from "@pages/Content/AboutUsPage";
import Advertise from "@pages/Content/Advertise";
import ContactUsPage from "@pages/Content/ContactUsPage";
import HelpPage from "@pages/Content/Help";
import RSSFeedPage from "@pages/Content/RSSFeedPage";
import DefaultPageHandler from "@pages/DefaultPageLayout";
import HomePage from "@pages/Home/Home.tsx";
import KeywordPage from "@pages/Keyword/KeywordPage";
import Mbo from "@pages/MBO/Mbo";
import MyBTIntroPage from "@pages/MyBT/Intro/MyBTIntroPage";
import MyBTPage from "@pages/MyBT/MyBTPage";
import MyBTOnboardPage from "@pages/MyBT/Onboard/MyBTOnboardPage";
import NewsletterAseanBusinessArchivePage from "@pages/Newsletter/Archive/AseanBusiness/NewsletterAseanBusinessArchivePage";
import NewsletterBigMoneyArchivePage from "@pages/Newsletter/Archive/BigMoney/NewsletterBigMoneyArchivePage";
import NewsletterEsgInsightsArchivePage from "@pages/Newsletter/Archive/EsgInsight/NewsletterEsgInsightsArchivePage";
import NewsletterGarageArchivePage from "@pages/Newsletter/Archive/Garage/NewsletterGarageArchivePage";
import NewsletterPropertyInsightArchivePage from "@pages/Newsletter/Archive/PropertyInsight/NewsletterPropertyInsightArchivePage";
import NewsletterThriveArchivePage from "@pages/Newsletter/Archive/Thrive/NewsletterThriveArchivePage";
import NewsletterSignupPage from "@pages/Newsletter/SignUp/NewsletterSignupPage";
import NewsletterUnsubscribe from "@pages/Newsletter/Unsubscribe/NewsletterUnsubscribe";
import NewsletterUnsubscribeSuccess from "@pages/Newsletter/Unsubscribe/NewsletterUnsubscribeSuccess";
import SearchPage from "@pages/Search/SearchPage";
import EventsAwardsE50Page from "@pages/Section/layouts/EventsAwardsPage/E50/EventsAwardsE50Page";
import EventsAwardsPage from "@pages/Section/layouts/EventsAwardsPage/EventsAwardsPage";
import LifestylePage from "@pages/Section/layouts/Lifestyle/Lifestyle";
import LifestyleLuxePage from "@pages/Section/layouts/Lifestyle/LifestyleLuxePage";
import PodcastsKeywordPage from "@pages/Section/layouts/PodcastsKeywordPage/PodcastsKeywordPage";
import PodcastPage from "@pages/Section/layouts/PodcastsPage/PodcastsPage";
import SectionPage from "@pages/Section/Section";
import SingaporeBudgetPage from "@pages/SingaporeBudget/SingaporeBudgetPage";
import ThriveAboutUsPage from "@pages/Thrive/AboutUs/ThriveAboutUs";
import ThriveEventsPage from "@pages/Thrive/Events/ThriveEvents";
import ThriveHomePage from "@pages/Thrive/Home/ThriveHome";
import VideosDetailsPage from "@pages/Videos/components/VideosDetailsPage";
import VideosPage from "@pages/Videos/VideosPage";
import { fetchContext } from "@sphtech/web2-core/ssr";
import {
  ENVIRONMENT,
  sectionNavigationItems,
  sectionNavigationItemsExcludedInRouter,
} from "@util/constant";
import {
  checkIsAseanArticle,
  checkIsBigMoneyArticle,
  checkIsEsgArticle,
  checkIsHubArticle,
  checkIsPropertyArticle,
  checkIsPropertySupplementsArticle,
  checkIsThriveArticle,
  checkIsWealthSupplementsArticle,
} from "@util/helpers";
import { http } from "@util/httpClient";
import { AxiosResponse } from "axios";

import FaqPage from "./pages/Content/FaqPage";
import HubPage from "./pages/Hub/HubPage";
import MyAccountPage from "./pages/MyAccount/MyAccountPage";
import PulsePage from "./pages/Pulse/PulsePage";
import EventsAwardsEEPage from "./pages/Section/layouts/EventsAwardsPage/EE/EventsAwardsEEPage";
import EventsAwardsSBAPage from "./pages/Section/layouts/EventsAwardsPage/SBA/EventsAwardsSBAPage";
import EventsAwardsSCAPage from "./pages/Section/layouts/EventsAwardsPage/SCA/EventsAwardsSCAPage";
import EventsAwardsSIAPage from "./pages/Section/layouts/EventsAwardsPage/SIA/EventsAwardsSIAPage";
import PropertySupplementsPage from "./pages/Section/layouts/Property/PropertySupplementsPage";
import Verticals from "./pages/Section/layouts/Verticals/Verticals";
import WealthPage from "./pages/Section/layouts/Wealth/WealthPage";
import WealthSupplementsPage from "./pages/Section/layouts/Wealth/WealthSupplementsPage";
import ThrivePastIssuesPage from "./pages/Thrive/PastIssues/ThrivePastIssuesPage";
import App from "./App";
import { getDependentPathsOfStoryQueue } from "./helpers";
import {
  RouteFactory,
  routesPodcastsKeywords,
  routesToExcludeFromSection,
} from "./routePaths";

/**
 * @function fetchDefaultSearchResults
 * @description This function helps to fetch the latest 10 articles from OpenSearch for default Search results.
 *  These articles are shown on the Search Autocomplete dialog box when users had typed 2 or
 *  less characters in the Search input box.
 * @returns { Promise } A Promise object that represents the default search results from OpenSeach in DSL json
 *  format.
 */

export async function fetchDefaultSearchResults(): Promise<
  Array<SearchResultItemData>
> {
  const OPENSEARCH_SEARCH_API_URL: string = import.meta.env
    .VITE_OS_ENDPOINT as string;
  if (!OPENSEARCH_SEARCH_API_URL) return [];

  return http
    .post<
      OpensearchSearchLambdaRequestConfig,
      AxiosResponse<OpensearchSearchLambdaAxiosResponseType>
    >("/search/", {
      defaultSearch: {
        isSearch: false,
        sortLatest: false,
        breakingNews: true,
        size: 10,
      },
    })
    .then(
      (response: AxiosResponse<OpensearchSearchLambdaAxiosResponseType>) => {
        return response.data.body.defaultSearch.body;
      }
    );
}

/**
 *
 * @function defaultSearchLoader
 * @description This function helps to enable deferred data loading by fetching default search results
 *  for Search Dialog component.
 * @returns A data loader for layout route.
 */
export const defaultSearchLoader = async function defaultSearchLoader() {
  return defer({
    defaultSearchResults: fetchDefaultSearchResults(),
  });
};

export const reactRouterRoutes: RouteObject[] = [
  {
    path: RouteFactory.home,
    // Note: we are not only using route's errorElement here
    // because we use sentry's error boundary for dev env
    element: (
      <ErrorBoundary fallback={CustomError}>
        <App />
      </ErrorBoundary>
    ),
    ErrorBoundary: CustomError,
    loader: defaultSearchLoader,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.myBT,
        element: <MyBTPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.myBTOnboard,
        element: <MyBTOnboardPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.myBTIntro,
        element: <MyBTIntroPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.search,
        element: <SearchPage />,
      },
      {
        path: RouteFactory.breakingNews,
        element: <BreakingNewsPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.wealth,
        element: <WealthPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.wealthSupplements,
        element: <WealthSupplementsPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.propertySupplements,
        element: <PropertySupplementsPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.thrive,
        element: <ThriveHomePage />,
      },
      {
        path: RouteFactory.thriveEvents,
        element: <ThriveEventsPage />,
      },
      {
        path: RouteFactory.thrivePastIssues,
        element: <ThrivePastIssuesPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.thriveAboutUs,
        element: <ThriveAboutUsPage />,
      },
      {
        path: RouteFactory.lifestyle,
        element: (
          <React.Suspense fallback={<Spinner />}>
            <LifestylePage />
          </React.Suspense>
        ),
        loader: fetchContext,
      },
      {
        path: RouteFactory.lifestyleLuxe,
        element: <LifestyleLuxePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.globalEnterprise,
        element: <Verticals />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.aseanBusiness,
        element: <Verticals />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.sgsme,
        element: <Verticals />,
        loader: fetchContext,
      },
      {
        path: `${RouteFactory.globalEnterprise}/latest`,
        element: (
          <React.Suspense fallback={<Spinner />}>
            <SectionPage />
          </React.Suspense>
        ),
        loader: fetchContext,
      },
      {
        path: `${RouteFactory.aseanBusiness}/latest`,
        element: (
          <React.Suspense fallback={<Spinner />}>
            <SectionPage />
          </React.Suspense>
        ),
        loader: fetchContext,
      },
      {
        path: `${RouteFactory.sgsme}/latest`,
        element: (
          <React.Suspense fallback={<Spinner />}>
            <SectionPage />
          </React.Suspense>
        ),
        loader: fetchContext,
      },
      {
        path: RouteFactory.aboutUs,
        element: <AboutUs />,
      },
      {
        path: RouteFactory.newsletterSignup,
        element: (
          <React.Suspense fallback={<Spinner />}>
            <NewsletterSignupPage />
          </React.Suspense>
        ),
      },
      {
        path: RouteFactory.newsletterThriveArchivePage,
        element: <NewsletterThriveArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.newsletterBigMoneyArchivePage,
        element: <NewsletterBigMoneyArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.newsletterUnsubscribe,
        element: <NewsletterUnsubscribe />,
      },
      {
        path: RouteFactory.newsletterUnsubscribeSuccess,
        element: <NewsletterUnsubscribeSuccess />,
      },
      {
        path: RouteFactory.newsletterGarageArchivePage,
        element: <NewsletterGarageArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.newsletterPropertyInsightArchivePage,
        element: <NewsletterPropertyInsightArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.newsletterEsgInsightsArchivePage,
        element: <NewsletterEsgInsightsArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.newsletterAseanBusinessArchivePage,
        element: <NewsletterAseanBusinessArchivePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.contactUs,
        element: <ContactUsPage />,
      },
      {
        path: RouteFactory.help,
        element: <HelpPage />,
      },
      {
        path: RouteFactory.budget2024,
        element: <SingaporeBudgetPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.podcasts,
        element: <PodcastPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.keywordsBudget2024,
        element: <SingaporeBudgetPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.keywordsBudget2025,
        element: <SingaporeBudgetPage />,
        loader: fetchContext,
      },
      ...routesPodcastsKeywords.map((route) => {
        return {
          path: route,
          element: <PodcastsKeywordPage />,
          loader: fetchContext,
        };
      }),
      {
        path: RouteFactory.keywords(":keyword"),
        element: <KeywordPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.hub,
        element: <HubPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.advertise,
        element: <Advertise />,
      },
      {
        path: RouteFactory.faq,
        element: <FaqPage />,
      },
      {
        path: RouteFactory.section("events-awards"),
        element: <EventsAwardsPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.section("events-awards/enterprise-50"),
        element: <EventsAwardsE50Page />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.section("events-awards/emerging-enterprise"),
        element: <EventsAwardsEEPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.section("events-awards/singapore-business-awards"),
        element: <EventsAwardsSBAPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.section("events-awards/singapore-corporate-awards"),
        element: <EventsAwardsSCAPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.section(
          "events-awards/sustainability-impact-awards"
        ),
        element: <EventsAwardsSIAPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.pulse,
        element: <PulsePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.pulseSlide(":articleId"),
        element: <PulsePage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.myAccount,
        element: <MyAccountPage />,
      },
      // map each individual section unique name as the route as to avoid conflict with articles' route pattern
      ...Object.values(sectionNavigationItems).reduce(
        (_routes: RouteObject[], navigationItem: NavigationItem) => {
          if (
            !sectionNavigationItemsExcludedInRouter.includes(
              navigationItem.key
            ) &&
            !routesToExcludeFromSection.includes(`/${navigationItem.key}`)
          ) {
            _routes = [
              ..._routes,
              {
                path: RouteFactory.section(navigationItem.key),
                element: <SectionPage />,
                loader: fetchContext,
              },
            ];
          }

          return _routes;
        },
        []
      ),
      ...Object.values(sectionNavigationItems).map(
        (navigationItem: NavigationItem) => {
          return {
            path: `/${navigationItem.key}/:id`,
            element: <DefaultPageHandler />,
            loader: fetchContext,
          };
        }
      ),
      {
        path: RouteFactory.mbo,
        element: <Mbo />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.rssFeeds,
        element: <RSSFeedPage />,
      },
      {
        path: RouteFactory.videos,
        element: <VideosPage />,
        loader: fetchContext,
      },
      {
        path: RouteFactory.videosDetailsPage(":slug", ":id"),
        element: <VideosDetailsPage />,
        loader: fetchContext,
      },
      {
        path: "/authors/:uri",
        element: <AuthorPage />,
        loader: fetchContext,
      },
      {
        path: "/article/:id",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/cue-id/:id",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/:parentCategory/:childCategory/:storyThread/:path",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/:parentCategory/:childCategory/:path",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/:parentCategory/:storyThread/:path",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/:parentCategory/:path",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
      {
        path: "/:path",
        element: <DefaultPageHandler />,
        loader: fetchContext,
      },
    ],
  },
];

/**
 * @constant routes
 * @description This constant is used to store all the routes in the application.
 * @returns { Array } An array of routes in the application.
 * @example routes = ["/", "/search", "/article/:id", "/:parentCategory/:childCategory?", "/breaking-news", "/about-us", "*"]
 */
export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  []
);

/**
 * This function helps to generate the dependent paths for SSG whenever there is an incoming SQS payload.
 * During SSG, the Web2 render Lambda function will invoke this function to get a list of additional routes to
 * render.
 * @param {PartialCueArticle} content Content parsed from incoming SQS payload.
 * @returns {Array<string>} A list of dependent paths for SSG.
 */
export const getDependentPaths = function getDependentPaths(
  content: PartialCueArticle | CaasConcisePayload | OriginResponsePayload
): string[] | Promise<string[]> {
  if (
    isContentConcisePayload(content) ||
    isContentFromOriginResponse(content)
  ) {
    return new Promise((resolve, reject) => {
      if (
        isContentConcisePayload(content) &&
        content.payload.type === "storyQueue"
      ) {
        resolve(getDependentPathsOfStoryQueue(content));
      } else {
        const urlPath = isContentConcisePayload(content)
          ? content.payload.urlPath
          : content.urlPath;
        const artQuery = genArticleUrlPathQuery(urlPath);

        if (!urlPath) {
          reject(new Error("Empty URL path encountered"));
          return;
        }
        queryCaasOpenSearch(artQuery)
          .then((artResponse) => {
            const hits = artResponse?.payload?.hits?.hits;
            resolve(getDependentPathsFromData(hits?.[0]?._source));
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error fetching dependent paths:", error);
            reject(error); // Reject the promise with the error
          });
      }
    });
  }

  return getDependentPathsFromData(content);
};

function getDependentPathsFromData(content: PartialCueArticle): string[] {
  let dependentPaths: string[] = [];
  try {
    const id = getEnvStoryQueueId("67395", "662114", "50", ENVIRONMENT);

    if (content?.data?.context?.type === "storyQueue") {
      // TODO: Need to handle rendering different pages based on where the story queue is rendering.
      // For now, rendering the home page as a default.
      if (content?.data?.context?.id === id) {
        dependentPaths = ["", "/pulse", "/bt-mbo"];
      } else {
        dependentPaths = ["", "/pulse"];
      }
    } else {
      const tags: Tag[] = [];
      let elements: Element[] = [];

      if (content && content?.data && content?.data?.context) {
        ({ elements = [] } = content.data.context);
      }

      dependentPaths = ["", "/pulse", "/breaking-news"];

      const isWealthSupplementsArticle = checkIsWealthSupplementsArticle(tags);

      if (isWealthSupplementsArticle) {
        dependentPaths = dependentPaths.concat("/wealth/supplements");
      }

      const isPropertySupplementsArticle =
        checkIsPropertySupplementsArticle(tags);

      if (isPropertySupplementsArticle) {
        dependentPaths = dependentPaths.concat("/property-supplements");
      }

      const isHubArticle = checkIsHubArticle(elements);
      if (isHubArticle) dependentPaths = dependentPaths.concat("/hub");

      const isBigMoneyArticle = checkIsBigMoneyArticle(tags);
      if (isBigMoneyArticle)
        dependentPaths = dependentPaths.concat(
          RouteFactory.newsletterBigMoneyArchivePage
        );

      const isThriveArticle = checkIsThriveArticle(tags);

      if (isThriveArticle) {
        dependentPaths = dependentPaths.concat(
          RouteFactory.newsletterThriveArchivePage
        );

        dependentPaths = dependentPaths.concat(RouteFactory.thrivePastIssues);
      }

      const isEsgArticle = checkIsEsgArticle(tags);

      if (isEsgArticle) {
        dependentPaths = dependentPaths.concat(
          RouteFactory.newsletterEsgInsightsArchivePage
        );
      }
      const isAseanArticle = checkIsAseanArticle(tags);

      if (isAseanArticle) {
        dependentPaths = dependentPaths.concat(
          RouteFactory.newsletterAseanBusinessArchivePage
        );
      }
      const isPropertyArticle = checkIsPropertyArticle(tags);

      if (isPropertyArticle) {
        dependentPaths = dependentPaths.concat(
          RouteFactory.newsletterPropertyInsightArchivePage
        );
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("An error occurred while processing dependent paths:", error);
  }

  return dependentPaths;
}

/**
 * This function is a type guard to check whether the content data matches that of the CaaS's full payload
 * implementation.
 * @param { PartialCueArticle | CaasConcisePayload | OriginResponsePayload } content
 * @returns { boolean } A boolean flag to indicate whether the incoming payload is of CaaS's concise payload type.
 */
const isContentConcisePayload = function isContentConcisePayload(
  content: PartialCueArticle | CaasConcisePayload | OriginResponsePayload
): content is CaasConcisePayload {
  const isEmptyObj =
    typeof content === "object" && Object.keys(content).length === 0;
  const hasMetaEventType =
    "_meta" in content &&
    "eventType" in content._meta &&
    Object.values(CaasEvent).includes(
      content._meta.eventType as unknown as CaasEvent
    );
  const hasCueState =
    "payload" in content &&
    "state" in content.payload &&
    Object.values(CueEventState).includes(
      content.payload.state as unknown as CueEventState
    );

  return !isEmptyObj && hasMetaEventType && hasCueState;
};

const isContentFromOriginResponse = function isContentFromOriginResponse(
  content: PartialCueArticle | CaasConcisePayload | OriginResponsePayload
): content is OriginResponsePayload {
  const isEmptyObj =
    typeof content === "object" && Object.keys(content).length === 0;

  const keys = Object.keys(content);
  const hasOnlyUrlPath = keys.length === 1 && keys[0] === "urlPath";

  return !isEmptyObj && hasOnlyUrlPath;
};
