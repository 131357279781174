import { RouteFactory } from "@app/routePaths";
import { BlockTitle } from "@blocks/Blocks";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import BrightcoveVideoPlaylist from "@components/Brightcove/components/BrightdcoveVideoPlaylist/BrightcoveVideoPlaylist";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getStaticSectionJSONLD } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import MoreVideos from "./components/MoreVideos";
import { VideosPageContext } from "./utils/types";

export type VideoPageContentProps = Pick<VideosPageContext, "data">;

export default function VideoPageContent({
  data: { title, videos },
}: VideoPageContentProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div className="w-full" data-testid="video-page-content-component">
      <AdSettings
        adNames={["lb1", "imu2", "prestitial", "catfish", "abm"]}
        path={RouteFactory.videos}
        adTargets={[{ key: "page", value: "listing" }]}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={RouteFactory.videos}
        jsonLd={getStaticSectionJSONLD("Videos", RouteFactory.videos)}
      />

      <GAData
        title="Videos_Index"
        level2="videos"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="w-full">
        <h1
          className={
            "border-b border-gray-850 pb-3 pt-6 font-playfair text-8xl uppercase"
          }
        >
          Videos
        </h1>
      </Container>

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-8">
            <BlockTitle text="FEATURED VIDEOS" rootClassName="mb-3" />

            <BrightcoveVideoPlaylist className="mb-6" />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-8">
            <Ad
              adType="imu2"
              className="flex flex-col items-center justify-center lg:sticky lg:top-[70px]"
            />
          </Column>
        </Row>

        <Row>
          <Column rootClassName="w-full mb-8">
            <MoreVideos videos={videos} className="mb-6" />
          </Column>
        </Row>
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
