import { useDetectParentContainerVisible } from "@hooks/useDetectParentContainerVisible";
import { useDetectWhiteOverlay } from "@hooks/useDetectWhiteOverlay";
import MyAccountAnonTooltip from "@pages/MyAccount/components/MyAccountHeader/MyAccountAnonTooltip";

export default function MyAccountFactoryAnonTooltip(): React.ReactElement {
  const { isOverlayDisplay } = useDetectWhiteOverlay();
  const { isParentDisplay } = useDetectParentContainerVisible({
    parentId: "#my-account-anon-trigger",
  });

  if (!isOverlayDisplay && isParentDisplay) return <MyAccountAnonTooltip />;

  return <></>;
}
