import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

export type useSetPageParamProps = {
  cardIndex: number;
  pageSize?: number;
};

export const useSetPageParam = ({
  cardIndex,
  pageSize = 10,
}: useSetPageParamProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setURLParams = (page: string) => {
    if (page === "1") {
      searchParams.delete("page");
      setSearchParams(searchParams, { preventScrollReset: true });
      return;
    }

    setSearchParams(
      { ...searchParams, page: page },
      { preventScrollReset: true }
    );
  };

  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (cardIndex % pageSize !== 0) return;
      if (!inView) return;

      const page = (cardIndex / pageSize + 1).toString();
      setURLParams(page);
    },
  });

  return {
    ref,
  };
};
