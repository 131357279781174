import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { renderPageTitleV2 } from "@app/components/MetaTags/helpers";
import { AccessFlag, Name, Type } from "@app/types/enums";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import {
  getArticleAuthorNames,
  getArticleChapter1,
  getArticleKeywords,
  getArticleLevel2,
  getArticleStoryThread,
} from "@components/GAData/helper";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import MetaTags from "@components/MetaTags/MetaTags";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import useGenerateGiftURL from "@hooks/useGenerateGiftURL";
import { useGetProgressWidth } from "@hooks/useGetProgressWidth";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useHookUpdateUrlArticle } from "@hooks/useHookUpdateUrlArticle";
import { useWindowSize } from "@hooks/useWindowSize";
import ArticleAttachments from "@pages/Article/components/ArticleAttachments";
import ArticleAuthorsList from "@pages/Article/components/ArticleAuthorsList";
import ArticleKeywords, {
  KeywordDisplayOption,
} from "@pages/Article/components/ArticleKeywords";
import ArticleKicker from "@pages/Article/components/ArticleKicker";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import ArticlesFromSameSection from "@pages/Article/components/ArticlesFromSameSection/ArticlesFromSameSection";
import ArticleSocialSharing from "@pages/Article/components/ArticleSocialSharing";
import ArticleSubscriberLabel from "@pages/Article/components/ArticleSubscriberLabel";
import ArticleSubshare from "@pages/Article/components/ArticleSubshare/ArticleSubshare";
import ArticleSubshareSent from "@pages/Article/components/ArticleSubshare/ArticleSubshareSent";
import OutBrainWidget from "@pages/Article/components/OutBrainWidget";
import RelatedArticles from "@pages/Article/components/RelatedArticles/RelatedArticles";
import TelegramBanner from "@pages/Article/components/TelegramBanner";
import { ArticleProps } from "@pages/Article/types/Article";
import { checkDisplayPaywall } from "@pages/Article/utils/helpers";
import useMyBtStore from "@store/useMyBtStore";
import useOKTAUserStore from "@store/useOKTAUserStore";
import useRecentReadStore from "@store/useRecentReadStore";
import { dayjsSingaporeTimezone } from "@util/constant";
import {
  checkIsHubArticle,
  gaEventTracker,
  getArticleJSONLD,
  getFieldValue,
  getFirstParagraph,
  getKickerFromArticleDataObject,
  replaceTextSingleQuote,
  toUnderscoreFromSpace,
} from "@util/helpers";
import dayjs from "dayjs";
import { find } from "lodash-es";

export default function ArticleDisplayInfographics({
  article,
  isGiftReceived,
  seeAlso,
  sectionNews,
  variant,
  index,
  isNavigate,
  setArticleObj,
  isOutbrainEnabled = true,
  isPreview = false,
  gsChannels,
  articleInView,
}: ArticleProps): React.ReactElement {
  // De-structure of article so it is cleaner to get the fields.
  const {
    id,
    title,
    updated: publishedDate,
    edited: updatedDate,
    authors,
    sections,
    tags,
    paywall,
    displaySetting,
    embedCodes,
    elements,
    relatedContributorProfile,
    sanitizedRelatedStories,
    shortUrl,
    urlPath,
    storyline,
    attachments,
  } = article;

  const { gsChannels: gsChannelsAPI } = useGrapeshotData(urlPath, index);

  const mainSection = sections?.[0];
  const articleCategory =
    mainSection?.parent.name !== "Home"
      ? mainSection?.parent.name
      : mainSection.name;
  const kicker = getKickerFromArticleDataObject(article);
  const contentAccess = paywall?.contentAccess || "0";
  const displayPaywall = checkDisplayPaywall({
    isGiftReceived,
    variant: variant,
    contentAccess: contentAccess,
  });
  const showRelatedLinksAndNewsletterSignupForm =
    displaySetting?.showRelatedLinksAndNewsletterSignupForm || false;

  const standFirstField =
    find(elements, { type: Type.Standfirst })?.fields || [];
  const standFirst = getFieldValue(standFirstField, Name.Standfirst);
  const isPremium = contentAccess === "1";

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const {
    alacrityRes,
    isModalOpen,
    setIsModalOpen,
    handleGenerateGiftURLModal,
  } = useGenerateGiftURL(isPremium);

  const firstParagraph = getFirstParagraph(article.elements || []);
  const { ref } = useHookUpdateUrlArticle(urlPath, isNavigate, article);

  const { isScreenLG } = useWindowSize();
  const { progressBarContainerRef, progressBarRef, handleSetProgressBarWidth } =
    useGetProgressWidth();

  const { ref: articleRef } = useInView({
    threshold: isScreenLG ? 0.1 : 0,
    onChange: (inView) => {
      if (inView) {
        setArticleObj(article);
      }
    },
  });

  const { ref: checkArticleTitleRef } = useInView({
    onChange: (inView) => {
      if (inView && articleInView?.title !== title) {
        setArticleObj(article);
      }
    },
  });

  const { ref: endOfArticle } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        gaEventTracker("end of article", "visible", article.urlPath);

        if (progressBarContainerRef.current) {
          progressBarContainerRef.current.style.opacity = "0";
        }
      }
    },
  });

  const isBrandedContent = checkIsHubArticle(article.elements || []);
  const titleReplaceSingleQuote = replaceTextSingleQuote(article.title);
  const myBTTags = useMyBtStore((store) => store.tags);

  const setArticleList = useRecentReadStore((state) => state.setArticleId);

  useEffect(() => {
    if (typeof window === "undefined") return;

    setArticleList({
      id,
      dateRead: dayjs().format("MM/DD/YYYY"),
    });
  }, [id, setArticleList]);
  return (
    <>
      <GAData
        articleid={id}
        author={getArticleAuthorNames(authors, relatedContributorProfile)}
        chapter1={mainSection ? getArticleChapter1(mainSection) : ""}
        contentcat={isPremium ? 2 : 1}
        contenttype="1"
        keyword={getArticleKeywords(tags || [])}
        level2={mainSection ? getArticleLevel2(mainSection) : ""}
        pubdate={dayjs(article.updated)
          .tz(dayjsSingaporeTimezone)
          .format("YYYY-MM-DD HH:mm:ss")}
        title={toUnderscoreFromSpace(titleReplaceSingleQuote)}
        product_flag={myBTTags.length > 0 ? "mybt" : "(not set)"}
        user_keyword={
          myBTTags.length > 0
            ? myBTTags.map((tag) => tag.name).join("|")
            : "(not set)"
        }
        story_threads={getArticleStoryThread(tags || [])?.name.toLowerCase()}
        gsChannels={index === 0 ? gsChannels : gsChannelsAPI}
      />

      <MetaTags
        title={renderPageTitleV2({
          kind: "art",
          title: article?.seoFields?.metaTitle || title,
        })}
        author={getArticleAuthorNames(authors, relatedContributorProfile)}
        articleId={article.id}
        description={article?.seoFields?.metaDescription || firstParagraph}
        keywords={article?.tags?.map((tag) => tag.name)}
        slug={article.urlPath}
        canonicalUrl={article?.seoFields?.canonicalUrl}
        imageUrl={article?.media?.[0]?.content?.fields?.["original-caas"]?.url}
        imageAlt={
          article?.media?.[0]?.summary?.find(
            (summaryItem) => summaryItem.key === "alttext"
          )?.value
        }
        jsonLd={getArticleJSONLD(article)}
        robots={"index, follow, max-image-preview:large"}
        gsChannels={index === 0 ? gsChannels : gsChannelsAPI}
        articleCategory={articleCategory}
        accessFlag={isPremium ? AccessFlag.Premium : AccessFlag.Free}
        publishedDate={publishedDate}
        updatedDate={updatedDate}
      />

      <ArticleSubshareSent {...{ alacrityRes, isModalOpen, setIsModalOpen }} />

      <ProgressBar
        progressBarContainerRef={progressBarContainerRef}
        progressBarRef={progressBarRef}
      />

      <article
        className="display-type--infographics w-full py-5 font-public-sans"
        data-index={index}
        data-cueid={id}
        data-url-path={urlPath}
        ref={ref}
      >
        <div ref={articleRef}>
          <div
            ref={(e) => {
              if (e?.offsetHeight) {
                handleSetProgressBarWidth(e);
              }
            }}
          >
            <Container rootClassName="pt-4">
              <Row>
                <Column rootClassName="w-full">
                  <>
                    {isPremium || kicker ? (
                      <div className="flex flex-wrap items-center space-x-1">
                        <ArticleKicker kicker={kicker} />

                        {isPremium && kicker ? (
                          <div className="font-poppins text-4xs tracking-[1px] text-gray-650">
                            ·
                          </div>
                        ) : null}

                        <ArticleSubscriberLabel isPremium={isPremium} />
                      </div>
                    ) : null}
                  </>

                  <h1
                    data-testid="article-title"
                    className="font-lct text-8xl font-bold leading-normal text-black md:text-12xl"
                  >
                    {title}
                  </h1>

                  <>
                    {typeof standFirst === "string" ? (
                      <p className="mt-2 font-poppins">{standFirst}</p>
                    ) : null}
                  </>

                  <>
                    {publishedDate && updatedDate ? (
                      <ArticleAuthorsList
                        rootClassName="mb-6 mt-2"
                        authors={authors}
                        contributors={relatedContributorProfile}
                        updatedDate={updatedDate}
                        publishedDate={publishedDate}
                        hasUpdatedTimeDisplayed={
                          displaySetting?.hasUpdatedTimeDisplayed
                        }
                      />
                    ) : null}
                  </>

                  <div className="mb-6 flex items-center justify-between border-b border-t border-gray-250 py-4">
                    {tags ? (
                      <ArticleKeywords
                        keywords={tags}
                        keywordDisplayOption={KeywordDisplayOption.First}
                      />
                    ) : null}

                    <div className="flex space-x-3">
                      <ArticleSocialSharing
                        articleTitle={title}
                        shareUrl={shortUrl}
                      />

                      <ArticleSubshare
                        isPremium={isPremium}
                        userType={OKTAUserInfo?.usertype}
                        {...{
                          handleGenerateGiftURLModal,
                          setIsModalOpen,
                          alacrityRes,
                        }}
                      />
                    </div>
                  </div>

                  <ArticleAttachments
                    attachments={attachments}
                    rootClassName="md:mx-8"
                  />

                  <div ref={checkArticleTitleRef} />

                  <>
                    {elements && storyline ? (
                      <ArticleParagraphs
                        className="body-content mb-6 mt-4 text-lg font-light text-gray-850"
                        {...{
                          id,
                          elements,
                          seeAlso,
                          embedCodes,
                          displayPaywall,
                          sections,
                          index,
                          gsChannels: index === 0 ? gsChannels : gsChannelsAPI,
                          isPremium,
                          storyline,
                          keywords: tags,
                          displayRelatedLinksAndNewsletterSignupForm:
                            showRelatedLinksAndNewsletterSignupForm,
                          isGifted: isGiftReceived,
                          isBrandedContent,
                          isPreview,
                          urlPath,
                        }}
                      />
                    ) : null}
                  </>

                  <div ref={endOfArticle}></div>

                  <>
                    {tags ? (
                      <ArticleKeywords
                        keywords={tags}
                        keywordDisplayOption={KeywordDisplayOption.Rest}
                      />
                    ) : null}
                  </>

                  <RelatedArticles articles={sanitizedRelatedStories} />

                  <TelegramBanner />

                  <>
                    {!isBrandedContent ? (
                      <ArticlesFromSameSection
                        data={sectionNews}
                        sections={sections}
                      />
                    ) : null}
                  </>
                </Column>

                <>
                  {article.urlPath && isOutbrainEnabled ? (
                    <OutBrainWidget
                      articleUrl={article.urlPath}
                      widgetId={index === 0 ? "AR_4" : "AR_5"}
                    />
                  ) : null}
                </>
              </Row>
            </Container>
          </div>
        </div>
      </article>
    </>
  );
}
