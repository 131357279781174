import { HtmlHTMLAttributes } from "react";
import { Tag } from "@app/types/Cue";
import { getFieldValueByKey } from "@pages/Keyword/utils/helpers";
import { FieldKey } from "@pages/Keyword/utils/types";
import { cn } from "@util/helpers";
import parse from "html-react-parser";

type DescriptionProps = {
  tag: Tag;
} & HtmlHTMLAttributes<HTMLDivElement>;

export default function Description({
  className,
  tag,
}: DescriptionProps): React.ReactElement {
  const displayName = getFieldValueByKey(tag.fields, FieldKey.Displayname);
  const description = getFieldValueByKey(tag.fields, FieldKey.Description);
  const logo = getFieldValueByKey(tag.fields, FieldKey.ImageDescriptionLogo);

  return (
    <div data-testid="description-component" className={className}>
      {displayName ? (
        <h1 className="mb-3 w-full font-playfair text-6xl font-semibold leading-none lg:text-8xl">
          {displayName.toUpperCase()}
        </h1>
      ) : null}

      {description || (logo && logo.includes("cassette")) ? (
        <div className="gap-6 lg:flex lg:items-end">
          {description ? (
            <div className="lg:w-7/12 [&_a]:font-bold [&_a]:hover:underline">
              {parse(description)}
            </div>
          ) : null}

          {logo && logo.includes("cassette") ? (
            <div
              className={cn(
                "mt-2 border-t border-gray-850 pt-2 lg:mt-0 lg:w-5/12 lg:border-none",
                { "lg:text-right": description }
              )}
            >
              <span className="font-poppins text-xs font-light">
                BROUGHT TO YOU BY
              </span>

              <img
                src={logo}
                alt=""
                width={240}
                height={120}
                className="ml-4 inline max-w-[120px]"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
