import { RouteFactory } from "@app/routePaths";
import { sectionNavigationItems } from "@util/constant";

export const sideDrawerNavigationBudget = [
  sectionNavigationItems["keywords/singapore-budget-2025"],
];

export const sideDrawerNavigation = [
  { ...sectionNavigationItems["breaking-news"], mobileLabel: "Breaking" },
  sectionNavigationItems.singapore,
  sectionNavigationItems.international,
  { ...sectionNavigationItems["companies-markets"], mobileLabel: "Companies" },
  sectionNavigationItems.property,
  { ...sectionNavigationItems["startups-tech"], mobileLabel: "Startups" },
  { ...sectionNavigationItems["opinion-features"], mobileLabel: "Opinion" },
  sectionNavigationItems.esg,
  sectionNavigationItems.wealth,
  sectionNavigationItems["working-life"],
  sectionNavigationItems.lifestyle,
  sectionNavigationItems["events-awards"],
];

export const sideDrawerNavigationBrands = [
  sectionNavigationItems["startups-tech/tech-in-asia"],
  sectionNavigationItems.thrive,
  sectionNavigationItems["international/asean"],
  sectionNavigationItems["singapore/smes"],
  sectionNavigationItems["international/global"],
];

export const sideDrawerNavigationRecommends = [
  sectionNavigationItems.newsletter,
  sectionNavigationItems.podcasts,
  sectionNavigationItems.videos,
  sectionNavigationItems.btclub,
];

export const sideDrawerNavigationAds = [
  { ...sectionNavigationItems.hub, label: "Content Hub" },
  sectionNavigationItems.advertise,
  { ...sectionNavigationItems["paid-press-release"], mobileLabel: "PR" },
];

export const sideDrawerNavigationTops = [
  { ...sectionNavigationItems.epaper, label: "Epaper", mobileLabel: "Epaper" },
  {
    ...sectionNavigationItems["keywords/singapore-budget-2025"],
    label: "Budget 2025",
    extraClass: "text-blue-200",
    trackingData: {
      event: "custom_event",
      eventCategory: "budget 2025",
      eventAction: "click",
      eventLabel: "nav bar",
    },
  },
];

export const topMobileNav = [
  {
    key: "home",
    link: RouteFactory.home,
    label: "Top Stories",
  },
  {
    ...sectionNavigationItems["breaking-news"],
    label: "Breaking",
  },
];

export const mastheadTopNav = [
  { ...sectionNavigationItems["international/asean"], label: "ASEAN" },
  { ...sectionNavigationItems["international/global"], label: "GLOBAL" },
  { ...sectionNavigationItems.advertise, label: "Advertise" },
  { ...sectionNavigationItems.paidPressRelease, label: "PR" },
];

export const mastheadTopNavConstant = [
  {
    section: {
      key: "masthead-top",
      children: mastheadTopNav.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
];

export const sideMenuConstant = [
  {
    section: {
      key: "hamburger-top",
      children: sideDrawerNavigationTops.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
  {
    section: {
      key: "hamburger-section",
      label: "Sections",
      children: sideDrawerNavigation.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
  {
    section: {
      key: "hamburger-brands",
      label: "Our Brands",
      children: sideDrawerNavigationBrands.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
  {
    section: {
      key: "hamburger-recommends",
      label: "BT Recommends",
      children: sideDrawerNavigationRecommends.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
  {
    section: {
      key: "hamburger-ads",
      children: sideDrawerNavigationAds.map((navItems) => {
        return {
          ...navItems,
          children:
            navItems.children?.map((navItemsChildren) => {
              return sectionNavigationItems[navItemsChildren];
            }) || [],
        };
      }),
    },
  },
];
