import { RouteFactory } from "@app/routePaths";
import Ad from "@components/Advertisement/Ad";
import AdSettings from "@components/Advertisement/AdSettings";
import { BRIGHTCOVE_ACCOUNT_ID } from "@components/Brightcove/utils/constants";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import BrightcoveVideoPlayer from "@components/Player/BrightcoveVideoPlayer";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBrightcovePlayerId from "@hooks/useBrightcovePlayerId";
import OutBrainWidget from "@pages/Article/components/OutBrainWidget";
import PulseEntry from "@pages/Pulse/components/PulseEntry/PulseEntry";
import { getVideoJSONLD, textToSlug } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import dayjs from "dayjs";

import RelatedVideos from "./RelatedVideos";

export type VideosDetailsPageContentProps = {
  title: string;
  video: BrightcoveVideo;
  relatedVideos: BrightcoveVideo[];
  videoUrl: string;
};

export default function VideosDetailsPageContent({
  title,
  video,
  relatedVideos,
  videoUrl,
}: VideosDetailsPageContentProps): React.ReactElement {
  const adSlotPath = RouteFactory.videos.replace("/", "");
  const adBlockDetected = useDetectAdBlock();
  const playerId = useBrightcovePlayerId();
  const jsonLd = getVideoJSONLD(video);

  return (
    <div className="w-full" data-testid="videos-details-page-content-component">
      <AdSettings
        adNames={["lb1", "imu1", "prestitial", "catfish", "abm"]}
        path={RouteFactory.videos}
        adTargets={[{ key: "page", value: "videos" }]}
        slotTargets={[{ key: "btarticleid", value: video.id }]} // This is the Brightcove video ID, as we do not have an article in CUE for videos.
      />

      <GAData
        title={textToSlug(video.name)}
        level2={adSlotPath}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <MetaTags
        title={title}
        description="Read more at The Business Times."
        ogType="article"
        slug={videoUrl}
        jsonLd={jsonLd}
      />

      <Ad
        adType="lb1"
        className="flex min-h-[50px] flex-col items-center justify-center border-b border-gray-175 bg-gray-125 py-1 text-center md:py-3 lg:min-h-[250px]"
      />

      <Header />

      <PulseEntry />

      <Container rootClassName="pt-6">
        <Column rootClassName="w-full mb-6">
          <h1 className="mb-2 font-lct text-8xl">{video.name}</h1>

          <div className="mb-3 flex items-center gap-1 border-y border-gray-175 py-2">
            <FontAwesomeIcon
              icon={faClock}
              className="text-gray-675"
              size="xs"
              height={12}
              width={12}
            />

            <time className="font-poppins text-3xs text-gray-675">
              {dayjs(video.published_at).format("ddd, MMMM DD, YYYY - hh:mm A")}
            </time>
          </div>

          <>
            {typeof playerId !== "undefined" ? (
              <BrightcoveVideoPlayer
                accountId={`${BRIGHTCOVE_ACCOUNT_ID}`}
                videoId={video.id}
                playerId={playerId}
                rootClassName="mb-6"
              />
            ) : null}
          </>

          <p className="tex-gray-850 font-poppins text-base">
            {video.description}
          </p>

          <Ad
            adType="imu1"
            className="flex flex-col items-center justify-center"
          />
        </Column>
      </Container>

      <div className="mb-6 bg-gray-250 py-4">
        <Container>
          <RelatedVideos videos={relatedVideos} />
        </Container>
      </div>

      <Container rootClassName="pb-6">
        <OutBrainWidget articleUrl={videoUrl} widgetId="AR_4" />
      </Container>

      <Ad adType="catfish" />

      <Ad adType="abm" />

      <Ad adType="prestitial" />
    </div>
  );
}
